<template>
	<v-content>
		<v-container fluid class="mt-12">
			<Error v-if="error"></Error>
			<v-row justify="center" v-else>
				<v-col cols="12" sm="8" md="4">
					<v-card class="elevation-0">
						<div class="title title-container mb-10">
							<h1>Crée ton mot de passe !</h1>
						</div>
						<v-card-text>
							<p class="sub-title-container">Choisis ton meilleur mot de passe pour sécuriser ton compte</p>
							<br />
							<v-form>
								<v-text-field
									v-model="password"
									:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPassword ? 'text' : 'password'"
									:rules="passwordRules"
									label="Ton nouveau mot de passe"
									@click:append="showPassword = !showPassword"
									color="black"
									outlined
									required
								></v-text-field>
								<v-text-field
									v-model="passwordToCheck"
									:append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPasswordConfirmation ? 'text' : 'password'"
									label="Confirme ton nouveau mot de passe"
									@click:append="showPasswordConfirmation = !showPasswordConfirmation"
									color="black"
									outlined
									required
								></v-text-field>
								<div class="matches" v-if="notSamePasswords">
									<p>Les mots de passe ne sont pas identiques</p>
								</div>
							</v-form>
						</v-card-text>
						<div class="button-validate-container">
							<v-btn
								class="hvr-grow button-validate"
								depressed
								large
								:disabled="checkIfPasswordConfirmed()"
								color="#15c39a"
								@click="validate()"
							>
								<span class="white--text">Validez le mot de passe</span>
							</v-btn>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import Error from '../views/layouts/Error';
export default {
	name: 'ResetPasswordComponent',
	components: {
		Error,
	},
	props: {
		userId: String,
	},
	data: () => ({
		password: '',
		showPassword: false,
		showPasswordConfirmation: false,
		error: false,
		passwordToCheck: '',
		passwordRules: [
			(v) => !!v || 'Le mot de passe est obligatoire',
			(v) =>
				(v.length >= 6 && /^.*[0-9].*$/.test(v)) ||
				'Le mot de passe doit avoir au moins 6 caractères et au moins 1 lettre et 1 chiffre',
		],
	}),
	methods: {
		async validate() {
			try {
				await this.$store.dispatch('accountManager/resetPassword', {
					userId: this.userId || this.$route.params.idUser,
					password: this.password,
				});
				if (process.env.VUE_APP_MIXPANEL == 'production' && this.$router.currentRoute.name == 'Onboarding') {
					this.$mixpanel.track('onboarding-resetPassword');
				}

				/** Any redirect params */
				const { onSuccessRedirectTo } = this.$route.query;
				if (onSuccessRedirectTo) {
					this.$router.push(onSuccessRedirectTo);
					return;
				}

				if (this.$router.currentRoute.name === 'OnboardingFormationTopic') {
					this.$emit('nextStep', 'Redirect to topic dashboard');
				} else if (this.$router.currentRoute.name != 'Onboarding') {
					this.$router.push('/dashboard');
				} else {
					this.$emit('nextStep', 'Intro');
				}
			} catch (error) {
				console.error(error);
			}
		},
		checkIfPasswordConfirmed() {
			if (/^.*[0-9].*$/.test(this.password) && this.password === this.passwordToCheck) {
				return false;
			} else {
				return true;
			}
		},
	},
	computed: {
		notSamePasswords() {
			return this.password !== this.passwordToCheck;
		},
	},
};
</script>

<style scoped>
.sub-title-container {
	text-align: center;
}
.title-container {
	text-align: center;
}
.matches {
	color: red;
}
.button-validate-container {
	text-align: center;
	padding-bottom: 20px;
}
.button-validate {
	color: white;
}
</style>
